import * as React from "react";
import {
    ActionButton,
    Checkbox, ChoiceGroup,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    Icon,
    IconButton,
    Layer,
    mergeStyleSets,
    Overlay,
    Popup,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField, Toggle
} from "@fluentui/react";
import changeProjectFinanceMethod from "../../../../../api/changeProjectFinanceMethod";
import MoneyField from "../../../../MoneyField";
import {FontSizes} from "@fluentui/theme";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {useEffect, useRef, useState} from "react";
import getUserCollectionMethod from "../../../../../api/getUserCollectionMethod";
import {datePickerStringsRu} from "../../../../data/datePickerStringsRu";
import updateFinanceWorkerMethod from "../../../../../api/updateFinanceWorkerMethod";
import makePrepaymentMethod from "../../../../../api/makePrepaymentMethod";
import removeFinanceWorkerMethod from "../../../../../api/removeFinanceWorkerMethod";
import PaymentSteps from "./PaymentSteps";
import Comments from "../../../../Shared/Comments";
import {useBoolean, useId} from "@fluentui/react-hooks";
import changeProjectSummaryPriceMethod from "../../../../../api/changeProjectSummaryPriceMethod";
import calculate from "./calculate";
import SpendingTable from "./SpendingTable";
import makeNewSpendingTableMethod from "../../../../../api/makeNewSpendingTableMethod";
import resetProjectSummaryPriceMethod from "../../../../../api/resetProjectSummaryPriceMethod";

let estSpendsBefore = null;
let spendsBefore = null;

const popupStyles = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.2)',
        bottom: '0',
        left: '0',
        position: 'fixed',
        right: '0',
        top: '0',
    },
    content: {
        background: 'white',
        left: '50%',
        maxWidth: '80%',
        padding: '0 2em 3em',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
});

export default function Finance({project, setProject, setNotification, reloadData, canChangeFinanceFact = false, isShorty = false, canFreeChangeSpendingTablePlan = false, canMakeNewSpendingTable = false, isProject = false}) {
    const [users, setUsers] = useState({loaded: false, data: []});
    const [estSpendsChanged, setEstSpendsChanged] = useState(false);
    const [spendsChanged, setSpendsChanged] = useState(false);
    const [enableToChangePrepaymentDate, setEnableToChangePrepaymentDate] = useState(false);
    // const [isSelectInputCalloutVisible, { toggle: toggleSelectInputCallout, setFalse: hideSelectInputCallout }] = useBoolean(false);
    const [otherInput, setOtherInput] = useState({});
    const spendingTableInfoBtn = useId('spending-table-info-btn');
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const [isSMPopupVisible, { setTrue: showSMPopup, setFalse: hideSMPopup }] = useBoolean(false);
    const popupRef = useRef(null);
    const [contractPriceChangeForm, setContractPriceChangeForm] = useState({status: 'INIT', type: null, upsellPrice: null, upsellPaymentType: null, upsellPaymentTitle: null, onlinePaymentId: null, newSum: null, isCanceled: false});
    const [isLocked, { toggle: toggleLock, setFalse: unlock }] = useBoolean(true);
    const [resetSummaryPriceForm, setResetSummaryPriceForm] = useState({summaryPrice: null});

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.classList.contains('ms-Button-flexContainer') && !event.target.classList.contains('ms-Dropdown-optionText') && event.target.nodeName !== 'INPUT') {
            clearContractPriceChangeForm();
            hidePopup();
            hideSMPopup();
        }
    };

    if (!estSpendsChanged) {
        estSpendsBefore = project.data.finance.estimatedSummarySpending;
    }
    if (!spendsChanged) {
        spendsBefore = project.data.finance.summarySpending;
    }

    useEffect(() => {
        if (!users.loaded) {
            getUserCollectionMethod().then(response => {
                if (response.error) {
                    console.log(response.error.message);
                    return;
                }

                setUsers({loaded: true, data: response});
            });
        }

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const changeFinance = (key, value, value2 = null, workers = null) => {
        const data = calculate(
            project,
            setProject,
            actualSummaryPrice,
            setEstSpendsChanged,
            setSpendsChanged,
            key,
            value,
            value2,
            workers
        );

        changeProjectFinanceMethod(data).then(response => {
            if (response.exception || response.error) {
                console.log(response.error ? response.error.message : response.exception);
                setNotification({type: 'error', text: 'Ошибка сервера'});
                return;
            }

            setNotification({type: 'success', text: 'Данные обновлены'});
        });
    };

    // const getCurrentParticipantUsersByType = (type) => {
    //     const participants = project.data.participants.filter((participant) => participant.type === type);
    //     const collection = [];
    //
    //     for (const participant of participants) {
    //         for (const user of users.data) {
    //             if (user.id === participant.userId) {
    //                 collection.push({
    //                     id: user.id,
    //                     projectsCount: user.projectsCount,
    //                     firstName: user.firstName,
    //                     lastName: user.lastName,
    //                     isValid: true,
    //                     key: user.id,
    //                     text: user.firstName + ' ' + (user.lastName ?? ''),
    //                 })
    //             }
    //         }
    //     }
    //
    //     return collection;
    // };

    const changeWorker = async (id, key, value) => {
        let workerId;
        const workers = project.data.finance.workers.map((worker) => {
            if (worker.id === id) {
                if ((key === 'userId' && worker.userId === null) || (key === 'username' && worker.userId !== null)) {
                    setOtherInput({...otherInput, [worker.id]: false});
                }
                if (key === 'username') {
                    worker.userId = null;
                    workerId = worker.id;
                }
                worker[key] = value;
                if (key === 'grossPrice') {
                    worker['grossPriceUpdatedAt'] = Date.now().valueOf();
                }
                if (key === 'planPrice') {
                    worker['planPriceUpdatedAt'] = Date.now().valueOf();
                    if (!worker['planPriceCreatedAt']) {
                        worker['planPriceCreatedAt'] = Date.now().valueOf();
                    }
                }
            }
            return worker;
        });

        setProject({...project, data: {...project.data, finance: {...project.data.finance, workers: workers}}});
        await updateFinanceWorkerMethod(project.data.id, id, key, value);
        if (workerId) {
            document.getElementById('worker-inp-' + workerId).focus();
        }
        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const removeWorker = async (id) => {
        const workers = project.data.finance.workers.filter((worker) => worker.id !== id);

        await removeFinanceWorkerMethod(project.data.id, id);
        await changeFinance('estimatedSummarySpending|summarySpending', calculatePlanSpending(workers), calculateFactSpending(workers), workers);

        setNotification({type: 'success', text: 'Данные сохранены'});
    };

    const getCurrentParticipants = (type) => {
        const participants = project.data.participants.filter((participant) => participant.type === type);
        const collection = [];

        for (const participant of participants) {
            for (const user of users.data) {
                if (user.id === participant.userId) {
                    collection.push(user.avatarId !== null
                        ? {
                            isValid: true,
                            key: user.id,
                            imageUrl: process.env.REACT_APP_API_URL + '/files/' + user.avatarId,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        }
                        : {
                            isValid: true,
                            key: user.id,
                            text: user.firstName + ' ' + (user.lastName ?? ''),
                        })
                }
            }
        }

        return collection;
    }

    const formatDate = (date) => {
        if (!date) return '';
        const month = datePickerStringsRu.monthDeclinations[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const calculateFactSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.grossPrice !== '') {
                spendingOnWorkers += parseFloat(worker.grossPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const calculatePlanSpending = (workers) => {
        let spendingOnWorkers = 0;
        workers.forEach((worker) => {
            if (worker.planPrice !== '') {
                spendingOnWorkers += parseFloat(worker.planPrice ?? 0);
            }
        });
        return spendingOnWorkers === 0 ? null : spendingOnWorkers;
    };

    const financeDisabled = project.data.finance.prepayment === null || project.data.finance.postPayment === null;

    const [prepaymentForm, setPrepaymentForm] = useState({id: project.data.id, summaryPrice: null, prepaymentSum: null, paymentType: null, services: project.data.services.map((service) => service.name)});

    const makePrepayment = async () => {
        const response = await makePrepaymentMethod(prepaymentForm.id, prepaymentForm.paymentType, prepaymentForm.summaryPrice, prepaymentForm.prepaymentSum, prepaymentForm.services);

        if (response.status === 'ok') {
            reloadData();
        }
    };

    if (financeDisabled) {
        return <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 300 } }}>
                <Dropdown
                    placeholder="Онлайн или по документам"
                    label="Тип платежа"
                    options={[
                        { key: 'ONLINE', text: 'Онлайн' },
                        { key: 'CLASSIC', text: 'По документам' },
                    ]}
                    selectedKey={prepaymentForm.paymentType}
                    onChange={(e, option) => setPrepaymentForm({...prepaymentForm, paymentType: option.key})}
                />
                <MoneyField label="Стоимость проекта" value={prepaymentForm.summaryPrice} onChange={(e) => setPrepaymentForm({...prepaymentForm, summaryPrice: e.target.value})} />
                <MoneyField label="Сумма предоплаты" value={prepaymentForm.prepaymentSum} onChange={(e) => setPrepaymentForm({...prepaymentForm, prepaymentSum: e.target.value})} />
                <PrimaryButton style={{marginTop: 30, height: 40}} text="Продолжить" onClick={makePrepayment} />
            </Stack>
        </Stack>;
    }

    const isEstimatedMarginTooLow = project.data.finance.estimatedMarginPercent <= 40;

    let actualSummaryPrice = 0;
    if (project.data.finance.prepayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.prepayment.sum);
    }

    if (project.data.finance.postPayment.status === 'PAID') {
        actualSummaryPrice += parseInt(project.data.finance.postPayment.sum);
    }

    project.data.finance.paymentSteps.forEach((payment, i) => {
        if (payment.status === 'PAID') {
            actualSummaryPrice += parseInt(payment.sum);
        }
    });

    const clearContractPriceChangeForm = () => {
        if (contractPriceChangeForm.status === 'DONE') {
            reloadData();
            setNotification({type: 'success', text: 'Стоимость проекта изменена'});
        }

        setContractPriceChangeForm({status: 'INIT', type: null, upsellPrice: null, upsellPaymentType: null, upsellPaymentTitle: null, onlinePaymentId: null, newSum: null, isCanceled: false})
    };

    const changeContractPrice = async () => {
        const response = await changeProjectSummaryPriceMethod(project.data.id, contractPriceChangeForm);
        if (response.status === 'ok') {
            changeFinance('summaryPrice', response.summaryPrice);
            setContractPriceChangeForm({...contractPriceChangeForm, status: 'DONE', onlinePaymentId: response.onlinePaymentId ?? null});
            await makeNewSpendingTableMethod(project.data.id);
            reloadData();
            unlock();
        }
    };

    const estimatedHistoryData = {
        summaryPrice: project.data.finance.summaryPriceHistory.length > 0 ? project.data.finance.summaryPriceHistory[0].oldSummaryPrice : 0,
        summarySpending: 0,
        summaryMargin: 0,
        marginPercent: 0
    };

    if (project.data.finance.spendingTableHistory.length > 0) {
        estimatedHistoryData.summarySpending = calculatePlanSpending(project.data.finance.spendingTableHistory[0].workers);

        if (estimatedHistoryData.summarySpending !== 0) {
            estimatedHistoryData.summaryMargin = estimatedHistoryData.summaryPrice - estimatedHistoryData.summarySpending;
            estimatedHistoryData.marginPercent = parseInt((100 / estimatedHistoryData.summaryPrice) * estimatedHistoryData.summaryMargin);
        } else {
            estimatedHistoryData.summaryMargin = 0;
            estimatedHistoryData.marginPercent = 0;
        }
    }

    return <div>
        <div style={{marginTop: 20, marginBottom: 20, width: 430}}>

            <PrimaryButton style={{height: 32, marginBottom: 20}} iconProps={{ iconName: 'Edit' }} allowDisabledFocus onClick={showSMPopup}>
                Изменить начальный план дохода
            </PrimaryButton>
            {isSMPopupVisible && <Layer>
                <Popup
                    className={popupStyles.root}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={hideSMPopup}
                    enableAriaHiddenSiblings={true}
                >
                    <Overlay />
                    <div ref={popupRef} role="document" className={popupStyles.content} style={{overflowY: 'auto', maxHeight: '90vh', paddingTop: 15, paddingLeft: 20, width: 350, height: 120, paddingBottom: 20}}>
                        <MoneyField label="Начальная сумма дохода, план" value={resetSummaryPriceForm.summaryPrice} onChange={(e) => setResetSummaryPriceForm({summaryPrice: e.target.value})} />
                        <PrimaryButton text="Изменить" style={{marginTop: 10, width: 120}} onClick={async () => {
                            await resetProjectSummaryPriceMethod(project.data.id, resetSummaryPriceForm.summaryPrice);
                            hideSMPopup();
                            reloadData();
                        }} disabled={resetSummaryPriceForm.summaryPrice === null} />
                    </div>
                </Popup>
            </Layer>}

            {isPopupVisible && <Layer>
                <Popup
                    className={popupStyles.root}
                    role="dialog"
                    aria-modal="true"
                    onDismiss={() => {
                        clearContractPriceChangeForm();
                        hidePopup();
                    }}
                    enableAriaHiddenSiblings={true}
                >
                    <Overlay />
                    <div ref={popupRef} role="document" className={popupStyles.content} style={{overflowY: 'auto', maxHeight: '90vh', paddingTop: 15, paddingLeft: 20, width: 350, height: 330, paddingBottom: 20}}>
                        <div style={{marginBottom: 1}}>
                            <h3 style={{marginTop: 0, marginBottom: 10}}>Изменение суммы контракта</h3>
                            <div style={{fontSize: 12, color: 'gray'}}>
                                {contractPriceChangeForm.type === null && 'Шаг 1 / 3'}
                                {contractPriceChangeForm.type !== null && contractPriceChangeForm.status === 'INIT' && 'Шаг 2 / 3'}
                                {contractPriceChangeForm.status === 'DONE' && 'Шаг 3 / 3'}
                            </div>
                            <IconButton aria-label="Close" iconProps={{ iconName: 'ChromeClose', style: {color: '#1d1d1d', fontSize: 12} }} style={{height: 30, width: 30, borderRadius: 100, position: 'absolute', right: 10, top: 10}} onClick={() => {
                                clearContractPriceChangeForm();
                                hidePopup();
                            }} />
                        </div>
                        <div style={{width: '100%', paddingTop: 15}}>
                            <Stack style={{width: contractPriceChangeForm.status === 'DONE' ? '95%' : 240}} tokens={{ childrenGap: 10 }}>
                                {contractPriceChangeForm.status === 'INIT' && <>
                                    {contractPriceChangeForm.type === null && <ChoiceGroup
                                        style={{marginTop: 40}}
                                        selectedKey={contractPriceChangeForm.type}
                                        options={[
                                            { key: 'UPSELL', text: 'Доп. работы' },
                                            { key: 'CUT', text: 'Отказ от услуг' },
                                        ]}
                                        onChange={(e, option) => setContractPriceChangeForm({...contractPriceChangeForm, type: option.key})}
                                        label="Выберите причину:"
                                    />}
                                    {contractPriceChangeForm.type === 'UPSELL' && <>
                                        <MoneyField label="Сумма доплаты" value={contractPriceChangeForm.upsellPrice} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPrice: e.target.value})} />
                                        <TextField label="Назначение платежа" value={contractPriceChangeForm.upsellPaymentTitle} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPaymentTitle: e.target.value})} />
                                        <Dropdown
                                            placeholder="Онлайн или по документам"
                                            label="Форма оплаты"
                                            options={[
                                                { key: 'ONLINE', text: 'Онлайн' },
                                                { key: 'CLASSIC', text: 'По документам' },
                                            ]}
                                            selectedKey={contractPriceChangeForm.upsellPaymentType}
                                            onChange={(e, option) => setContractPriceChangeForm({...contractPriceChangeForm, upsellPaymentType: option.key})}
                                        />
                                        <PrimaryButton text="Далее" style={{marginTop: 33, width: 120}} onClick={changeContractPrice} disabled={contractPriceChangeForm.upsellPrice === null || contractPriceChangeForm.upsellPaymentTitle === null || contractPriceChangeForm.upsellPaymentType === null} />
                                    </>}
                                    {contractPriceChangeForm.type === 'CUT' && <>
                                        <div style={{marginTop: 40}}>
                                            <MoneyField label="Новая стоимость контракта" value={contractPriceChangeForm.newSum ?? project.data.finance.summaryPrice} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, newSum: e.target.value})} />
                                        </div>
                                        <div style={{marginTop: 15}}>
                                            <Checkbox label="Расторжение контракта" checked={contractPriceChangeForm.isCanceled} onChange={(e) => setContractPriceChangeForm({...contractPriceChangeForm, isCanceled: e.target.checked})} />
                                        </div>
                                        <PrimaryButton text="Далее" style={{marginTop: 99, width: 120}} onClick={changeContractPrice} disabled={contractPriceChangeForm.newSum === null} />
                                    </>}
                                </>}
                                {contractPriceChangeForm.status === 'DONE' && <>
                                    <div style={{marginTop: 50}}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 5}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Стоимость проекта изменена</div>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 5}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Создана новая версия раскладки</div>
                                        </div>
                                        {contractPriceChangeForm.type === 'UPSELL' && <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Добавлен новый платёж</div>
                                        </div>}
                                        {contractPriceChangeForm.type === 'CUT' && contractPriceChangeForm.isCanceled && <div style={{display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20}}>
                                            <Icon iconName="Accept" style={{color: '#ef5021'}} />
                                            <div style={{color: 'gray'}}>Статус проекта изменён на CANCELED</div>
                                        </div>}
                                        {contractPriceChangeForm.type === 'UPSELL' && contractPriceChangeForm.upsellPaymentType === 'ONLINE' && <>
                                            <div style={{fontWeight: 600}}>Скопируйте ссылку для онлайн оплаты</div>
                                            {contractPriceChangeForm.onlinePaymentId && <DefaultButton text="Скопировать ссылку" style={{marginTop: 20}} iconProps={{iconName: 'Copy'}} onClick={async () => {
                                                await navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + contractPriceChangeForm.onlinePaymentId);
                                                clearContractPriceChangeForm();
                                                hidePopup();
                                                setNotification({type: 'success', text: 'Данные обновлены. Ссылка на онлайн-оплату скопирована.'});
                                            }} />}
                                        </>}
                                        {contractPriceChangeForm.type === 'UPSELL' && contractPriceChangeForm.upsellPaymentType === 'CLASSIC' && <>
                                            <div style={{fontWeight: 600}}>Загрузите ДС в файлы проекта</div>
                                        </>}
                                        {contractPriceChangeForm.type === 'CUT' && <>
                                            <div style={{fontWeight: 600}}>Скорректируйте платежи и загрузите ДС в файлы проекта</div>
                                        </>}
                                    </div>
                                </>}
                            </Stack>
                        </div>
                    </div>
                </Popup>
            </Layer>}
            <div style={{display: 'flex', gap: 20}}>
                <div style={{width: project.data.finance.summaryPriceHistory.length === 0 ? 430 : 570}}>
                    <div className="ms-fontWeight-semibold" style={{marginBottom: 10}}>Бюджет проекта</div>
                    {project.data.finance.summaryPriceHistory.length === 0
                        ? <DetailsList
                            styles={{ root: { paddingTop: 0 } }}
                            items={[
                                {
                                    name: 'Доходы',
                                    estimated: project.data.finance.summaryPrice,
                                    real: actualSummaryPrice,
                                },
                                {
                                    name: 'Расходы',
                                    estimated: project.data.finance.estimatedSummarySpending,
                                    real: project.data.finance.summarySpending,
                                },
                                {
                                    name: 'Маржа',
                                    estimated: project.data.finance.estimatedSummaryMargin,
                                    real: project.data.finance.summaryMargin,
                                },
                                {
                                    name: 'Маржа %',
                                    estimated: project.data.finance.estimatedMarginPercent,
                                    real: project.data.finance.marginPercent,
                                },
                            ]}
                            compact={false}
                            columns={[
                                {
                                    key: 'name',
                                    name: '',
                                    minWidth: 70,
                                    maxWidth: 70,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 10, color: 'gray'}}>
                                        {item.name}
                                    </div>,
                                },
                                {
                                    key: 'estimated',
                                    name: 'План',
                                    minWidth: 150,
                                    maxWidth: 150,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                        {item.name === 'Доходы' && <>
                                            <MoneyField value={item.estimated} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40, cursor: 'pointer'}} onClick={showPopup}>
                                                <Icon iconName="Edit" style={{color: '#ef5021'}} />
                                            </div>
                                        </>}
                                        {item.name === 'Расходы' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа %' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} suffix="%" />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                    </div>,
                                },
                                {
                                    key: 'real',
                                    name: 'Факт',
                                    minWidth: 150,
                                    maxWidth: 150,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                        {item.name === 'Доходы' && <>
                                            <MoneyField value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Расходы' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Маржа' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Маржа %' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} suffix="%" />
                                        </>}
                                    </div>,
                                },
                            ]}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => item.id}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            className="editableTable noMarginTop"
                        />
                        : <DetailsList
                            styles={{ root: { paddingTop: 0 } }}
                            items={[
                                {
                                    name: 'Доходы',
                                    estimated: estimatedHistoryData.summaryPrice,
                                    changed: project.data.finance.summaryPrice,
                                    real: actualSummaryPrice,
                                },
                                {
                                    name: 'Расходы',
                                    estimated: estimatedHistoryData.summarySpending,
                                    changed: project.data.finance.estimatedSummarySpending,
                                    real: project.data.finance.summarySpending,
                                },
                                {
                                    name: 'Маржа',
                                    estimated: estimatedHistoryData.summaryMargin,
                                    changed: project.data.finance.estimatedSummaryMargin,
                                    real: project.data.finance.summaryMargin,
                                },
                                {
                                    name: 'Маржа %',
                                    estimated: estimatedHistoryData.marginPercent,
                                    changed: project.data.finance.estimatedMarginPercent,
                                    real: project.data.finance.marginPercent,
                                },
                            ]}
                            compact={false}
                            columns={[
                                {
                                    key: 'name',
                                    name: '',
                                    minWidth: 70,
                                    maxWidth: 70,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingLeft: 10, color: 'gray'}}>
                                        {item.name}
                                    </div>,
                                },
                                {
                                    key: 'estimated',
                                    name: 'Начальный план',
                                    minWidth: 135,
                                    maxWidth: 135,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                        {item.name === 'Доходы' && <>
                                            <MoneyField value={item.estimated} disabled={true} />
                                        </>}
                                        {item.name === 'Расходы' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа %' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.estimated} disabled={true} suffix="%" />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                            </div>
                                        </>}
                                    </div>,
                                },
                                {
                                    key: 'changed',
                                    name: 'Изменённый план',
                                    minWidth: 150,
                                    maxWidth: 150,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                        {item.name === 'Доходы' && <>
                                            <MoneyField value={item.changed} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40, cursor: 'pointer'}} onClick={showPopup}>
                                                <Icon iconName="Edit" style={{color: '#ef5021'}} />
                                            </div>
                                        </>}
                                        {item.name === 'Расходы' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.changed} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.changed} disabled={true} />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                        {item.name === 'Маржа %' && <>
                                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} value={item.changed} disabled={true} suffix="%" />
                                            <div style={{backgroundColor: '#f3f2f1', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: 40}}>
                                            </div>
                                        </>}
                                    </div>,
                                },
                                {
                                    key: 'real',
                                    name: 'Факт',
                                    minWidth: 135,
                                    maxWidth: 135,
                                    isRowHeader: true,
                                    data: 'string',
                                    onRender: (item) => <div style={{display: 'flex', alignItems: 'center', height: '100%', color: 'gray'}}>
                                        {item.name === 'Доходы' && <>
                                            <MoneyField value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Расходы' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Маржа' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} />
                                        </>}
                                        {item.name === 'Маржа %' && <>
                                            <MoneyField style={{color: 'black'}} value={item.real} disabled={true} suffix="%" />
                                        </>}
                                    </div>,
                                },
                            ]}
                            selectionMode={SelectionMode.none}
                            getKey={(item, index) => item.id}
                            setKey="none"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            className="editableTable noMarginTop"
                        />
                    }
                </div>
                {/*{project.data.finance.summaryPriceHistory.length > 0 && <div style={{width: 640}}>*/}
                {/*    <div className="ms-fontWeight-semibold" style={{marginBottom: 10}}>Изменение суммы контракта</div>*/}
                {/*    <DetailsList*/}
                {/*        styles={{ root: { paddingTop: 0 }, contentWrapper: { maxHeight: 132, overflowX: 'hidden' } }}*/}
                {/*        items={project.data.finance.summaryPriceHistory}*/}
                {/*        compact={false}*/}
                {/*        columns={[*/}
                {/*            {*/}
                {/*                key: 'name',*/}
                {/*                name: 'Причина',*/}
                {/*                minWidth: 200,*/}
                {/*                maxWidth: 200,*/}
                {/*                isRowHeader: true,*/}
                {/*                data: 'string',*/}
                {/*                onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 32, backgroundColor: '#f3f2f1'}}>{item.name}</div>,*/}
                {/*            },*/}
                {/*            // {*/}
                {/*            //     key: 'delta',*/}
                {/*            //     name: 'Изменение',*/}
                {/*            //     minWidth: 100,*/}
                {/*            //     maxWidth: 100,*/}
                {/*            //     isRowHeader: true,*/}
                {/*            //     data: 'string',*/}
                {/*            //     onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 30}}>{item.direction === 'up' ? <div style={{color: 'green'}}>+{new Intl.NumberFormat().format(item.delta)}</div> : <div style={{color: 'red'}}>-{new Intl.NumberFormat().format(item.delta)}</div>}</div>,*/}
                {/*            // },*/}
                {/*            {*/}
                {/*                key: 'oldSummaryPrice',*/}
                {/*                name: 'Старая сумма',*/}
                {/*                minWidth: 110,*/}
                {/*                maxWidth: 110,*/}
                {/*                isRowHeader: true,*/}
                {/*                data: 'string',*/}
                {/*                onRender: (item) => <MoneyField value={item.oldSummaryPrice} disabled={true} />,*/}
                {/*            },*/}
                {/*            {*/}
                {/*                key: 'newSummaryPrice',*/}
                {/*                name: 'Новая сумма',*/}
                {/*                minWidth: 110,*/}
                {/*                maxWidth: 110,*/}
                {/*                isRowHeader: true,*/}
                {/*                data: 'string',*/}
                {/*                onRender: (item) => <MoneyField value={item.newSummaryPrice} disabled={true} style={{color: item.direction === 'up' ? '#1b5700' : '#5f0000'}} />,*/}
                {/*            },*/}
                {/*            {*/}
                {/*                key: 'date',*/}
                {/*                name: 'Дата изменения',*/}
                {/*                minWidth: 140,*/}
                {/*                maxWidth: 140,*/}
                {/*                isRowHeader: true,*/}
                {/*                data: 'string',*/}
                {/*                onRender: (item) => <div style={{paddingLeft: 10, display: 'flex', alignItems: 'center', height: 32, backgroundColor: '#f3f2f1'}}>{formatDate(new Date(item.date))}</div>,*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*        selectionMode={SelectionMode.none}*/}
                {/*        getKey={(item, index) => item.id}*/}
                {/*        setKey="none"*/}
                {/*        layoutMode={DetailsListLayoutMode.justified}*/}
                {/*        isHeaderVisible={true}*/}
                {/*        className="editableTable noMarginTop"*/}
                {/*    />*/}
                {/*</div>}*/}
            </div>
        </div>
        {/*<Pivot aria-label="Меню запроса" linkFormat="tabs" styles={{link: {height: 32, marginRight: 10, backgroundColor: 'rgb(248 248 246)'}}}>*/}
        {/*    <PivotItem headerText="Платежи клиента">*/}
                <div style={{marginTop: 20}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 9}}>
                        <div className="ms-fontWeight-semibold">Платежи</div>
                        <Toggle styles={{root: {marginBottom: 0}, pill: {height: 14, width: 32}, thumb: {height: 8, width: 8, borderWidth: 5}, container: {display: 'flex', alignItems: 'center'}}} role="checkbox" onText="Поэтапная оплата" offText="Поэтапная оплата" checked={project.data.finance.isSteppedPayment} onChange={(e, checked) => changeFinance('isSteppedPayment', checked)} />
                    </div>
                    {project.data.finance.prepayment && <PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} changeFinance={changeFinance} enableToChangePrepaymentDate={enableToChangePrepaymentDate} setEnableToChangePrepaymentDate={setEnableToChangePrepaymentDate} />}
                </div>
            {/*</PivotItem>*/}
            {/*<PivotItem headerText="Раскладка">*/}
                <SpendingTable
                    project={project}
                    changeWorker={changeWorker}
                    getCurrentParticipants={getCurrentParticipants}
                    otherInput={otherInput}
                    setOtherInput={setOtherInput}
                    changeFinance={changeFinance}
                    calculatePlanSpending={calculatePlanSpending}
                    canFreeChangeSpendingTablePlan={canFreeChangeSpendingTablePlan}
                    canMakeNewSpendingTable={canMakeNewSpendingTable}
                    calculateFactSpending={calculateFactSpending}
                    canChangeFinanceFact={canChangeFinanceFact}
                    removeWorker={removeWorker}
                    formatDate={formatDate}
                    isProject={isProject}
                    spendingTableInfoBtn={spendingTableInfoBtn}
                    reloadData={reloadData}
                    isLocked={isLocked}
                    toggleLock={toggleLock}
                />
        {/*    </PivotItem>*/}
        {/*</Pivot>*/}
        {/*<Separator/>*/}
        <div style={{marginTop: 40, marginBottom: 0}}>
            <div  className="ms-fontWeight-semibold" style={{ marginBottom: 10 }}>Комментарии</div>
            <Comments project={project} reloadData={reloadData} type="FINANCE" setNotification={setNotification} />
        </div>
    </div>;

    return <Stack horizontal tokens={{ childrenGap: 50 }} style={{paddingTop: 20}}>
        {isShorty
            ? <></>
            : <div style={{marginBottom: 10}}>
                <Stack horizontal tokens={{ childrenGap: 40 }} style={{alignItems: 'flex-start'}}>
                    {/*<div className="ms-fontWeight-semibold">Доходы план</div>*/}
                    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: 185 } }}>
                        <MoneyField label="Доходы план" value={project.data.finance.summaryPrice} onChange={(e) => changeFinance('summaryPrice', e.target.value)} disabled={financeDisabled} />
                    </Stack>
                    <div style={{paddingTop: 5}}>
                         <div className="ms-fontWeight-semibold">Доходы факт <div style={{marginTop: 12}}>{new Intl.NumberFormat().format(actualSummaryPrice ?? 0)} руб.</div></div>
                    </div>
                    {/*<Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                    {/*    <MoneyField label="Доходы факт" value={actualSummaryPrice ?? null} disabled={true} />*/}
                    {/*</Stack>*/}
                </Stack>
                {/*<Separator />*/}
            </div>
        }
        {/*<div>*/}
        {/*    <div className="ms-fontWeight-semibold">Платежи</div>*/}
        {/*    <div>*/}
        {/*        {project.data.finance.prepayment && <PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} changeFinance={changeFinance} enableToChangePrepaymentDate={enableToChangePrepaymentDate} setEnableToChangePrepaymentDate={setEnableToChangePrepaymentDate} />}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <Stack horizontal tokens={{ childrenGap: 30 }}>
            <div>
                {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.prepayment.title ?? 'Предоплата'}</div>*/}
                {/*<Stack tokens={{ childrenGap: 10 }}>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <TextField label="Название" value={project.data.finance.prepayment.title ?? 'Предоплата'} disabled={true} style={{color: 'black'}} />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <Dropdown*/}
                {/*            placeholder="Выберите"*/}
                {/*            label="Тип"*/}
                {/*            options={[*/}
                {/*                { key: 'ONLINE', text: 'Онлайн' },*/}
                {/*                { key: 'CLASSIC', text: 'По документам' },*/}
                {/*            ]}*/}
                {/*            selectedKey={project.data.finance.prepayment.type}*/}
                {/*            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}*/}
                {/*            styles={{ title: {color: 'black'}}}*/}
                {/*            onChange={(e, option) => changeFinance('prepaymentType', option.key)}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <MoneyField label="Сумма предоплаты" value={project.data.finance.prepayment.sum} disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'} onChange={(e) => changeFinance('prepaymentSum', e.target.value)} />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        <Dropdown*/}
                {/*            placeholder="Выберите"*/}
                {/*            label="Статус"*/}
                {/*            options={[*/}
                {/*                { key: 'NOT_NEED', text: 'Не нужна' },*/}
                {/*                { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },*/}
                {/*                { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },*/}
                {/*                { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },*/}
                {/*                { key: 'PAID', text: 'Оплачено' },*/}
                {/*            ]}*/}
                {/*            selectedKey={project.data.finance.prepayment.status}*/}
                {/*            disabled={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID'}*/}
                {/*            styles={project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' ? { title: {color: 'black'}} : {}}*/}
                {/*            onChange={(e, option) => changeFinance('prepaymentStatus', option.key)}*/}
                {/*        />*/}
                {/*    </Stack>*/}
                {/*    <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
                {/*        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status === 'PAID' && !enableToChangePrepaymentDate*/}
                {/*            ? <div onClick={() => setEnableToChangePrepaymentDate(true)}><TextField label="Дата оплаты" value={formatDate(new Date(project.data.finance.prepayment.paidAt))} disabled={true} style={{color: 'black'}} /></div>*/}
                {/*            : <DatePicker*/}
                {/*                firstDayOfWeek={DayOfWeek.Monday}*/}
                {/*                showWeekNumbers={false}*/}
                {/*                firstWeekOfYear={1}*/}
                {/*                showMonthPickerAsOverlay={true}*/}
                {/*                placeholder="Выберите дату"*/}
                {/*                ariaLabel="Выберите дату"*/}
                {/*                label="Дата оплаты"*/}
                {/*                strings={datePickerStringsRu}*/}
                {/*                formatDate={formatDate}*/}
                {/*                value={project.data.finance.prepayment.paidAt !== null ? new Date(project.data.finance.prepayment.paidAt) : null}*/}
                {/*                onSelectDate={(date) => changeFinance('prepaymentPaidAt', date.toDateString())}*/}
                {/*            />*/}
                {/*        }*/}
                {/*        {project.data.finance.prepayment.type === 'ONLINE' && project.data.finance.prepayment.status !== 'PAID'*/}
                {/*            && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.prepayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />*/}
                {/*        }*/}
                {/*    </Stack>*/}
                {/*</Stack>*/}
            </div>
            {/*{project.data.finance.postPayment.status === 'NOT_NEED'*/}
            {/*    ? <></>*/}
            {/*    : <>*/}
            {/*        <Separator vertical />*/}
            {/*        <div>*/}
            {/*            /!*<div style={{ fontSize: FontSizes.size14, marginBottom: 6, fontWeight: 'bold' }}>{project.data.finance.postPayment.title ?? 'Постоплата'}</div>*!/*/}
            {/*            <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>*/}
            {/*                <ActionButton style={{position: 'absolute', top: -20}} iconProps={{ iconName: 'Delete' }} allowDisabledFocus onClick={() => changeFinance('postPaymentStatus', 'NOT_NEED')} />*/}
            {/*            </div>*/}
            {/*            <Stack tokens={{ childrenGap: 10 }}>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <TextField label="Название" value={project.data.finance.postPayment.title ?? 'Постоплата'} disabled={true} style={{color: 'black'}} />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <Dropdown*/}
            {/*                        placeholder="Выберите"*/}
            {/*                        label="Тип"*/}
            {/*                        options={[*/}
            {/*                            { key: 'ONLINE', text: 'Онлайн' },*/}
            {/*                            { key: 'CLASSIC', text: 'По документам' },*/}
            {/*                        ]}*/}
            {/*                        selectedKey={project.data.finance.postPayment.type}*/}
            {/*                        styles={{ textField: {color: 'black'}}}*/}
            {/*                        onChange={(e, option) => changeFinance('postPaymentType', option.key)}*/}
            {/*                    />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <MoneyField label="Сумма постоплаты" value={project.data.finance.postPayment.sum} onChange={(e) => changeFinance('postPaymentSum', e.target.value)} />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <Dropdown*/}
            {/*                        placeholder="Выберите"*/}
            {/*                        label="Статус"*/}
            {/*                        options={[*/}
            {/*                            { key: 'NOT_NEED', text: 'Не нужна' },*/}
            {/*                            { key: 'WAITING_DOCS', text: 'Ожидает договор и счёт' },*/}
            {/*                            { key: 'WAITING_PAYMENT', text: 'Ожидает оплаты' },*/}
            {/*                            { key: 'VERIFYING', text: 'Клиент сказал, что оплатил, проверяем' },*/}
            {/*                            { key: 'PAID', text: 'Оплачено' },*/}
            {/*                        ]}*/}
            {/*                        selectedKey={project.data.finance.postPayment.status}*/}
            {/*                        onChange={(e, option) => changeFinance('postPaymentStatus', option.key)}*/}
            {/*                    />*/}
            {/*                </Stack>*/}
            {/*                <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>*/}
            {/*                    <DatePicker*/}
            {/*                        firstDayOfWeek={DayOfWeek.Monday}*/}
            {/*                        showWeekNumbers={false}*/}
            {/*                        firstWeekOfYear={1}*/}
            {/*                        showMonthPickerAsOverlay={true}*/}
            {/*                        placeholder="Выберите дату"*/}
            {/*                        ariaLabel="Выберите дату"*/}
            {/*                        label="Дата оплаты"*/}
            {/*                        strings={datePickerStringsRu}*/}
            {/*                        formatDate={formatDate}*/}
            {/*                        value={project.data.finance.postPayment.paidAt !== null ? new Date(project.data.finance.postPayment.paidAt) : null}*/}
            {/*                        onSelectDate={(date) => changeFinance('postPaymentPaidAt', date.toDateString())}*/}
            {/*                        disabled={project.data.finance.postPayment.status === 'NOT_NEED'}*/}
            {/*                    />*/}
            {/*                    {project.data.finance.postPayment.type === 'ONLINE' && project.data.finance.postPayment.status !== 'PAID'*/}
            {/*                        && <DefaultButton text="Скопировать ссылку на оплату" iconProps={{ iconName: 'Copy' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_API_URL + '/online-payment/' + project.data.finance.postPayment.onlinePayment.id)  && setNotification({type: 'success', text: 'Ссылка на оплату скопирована'})} />*/}
            {/*                    }*/}
            {/*                </Stack>*/}
            {/*            </Stack>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*}*/}
            {/*<PaymentSteps project={project} setProject={setProject} setNotification={setNotification} reloadData={reloadData} />*/}
        </Stack>
        {isShorty
            ? <></>
            : <>
                {/*<Separator />*/}
                <div style={{marginTop: 10}}>
                    <div className="ms-fontWeight-semibold" style={{ marginBottom: 6 }}>Расходы</div>
                    <div style={{fontSize: 14, marginTop: 3, marginBottom: 16, marginLeft: 0, fontWeight: 400, color: 'gray'}}>
                        Расходы считаются автоматически когда вносите данные в раскладку.
                        {isEstimatedMarginTooLow && <>
                            <br /><span style={{color: 'red'}}>Маржа должна быть выше 40%</span>
                        </>}
                        {/*<br/>Если вы случайно изменили начальное и корректное значение расходов, оно высветиться ниже - снова впишите его, чтобы вернуть.*/}
                    </div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>План</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Расходы план" value={project.data.finance.estimatedSummarySpending} onChange={(e) => changeFinance('estimatedSummarySpending', e.target.value)} disabled={true} />
                            {estSpendsChanged && estSpendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(estSpendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} label="Маржа план руб." value={project.data.finance.estimatedSummaryMargin} onChange={(e) => changeFinance('estimatedSummaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа план %" type="number" suffix="%" value={project.data.finance.estimatedMarginPercent} onChange={(e) => changeFinance('estimatedMarginPercent', e.target.value)} disabled={true} style={{color: isEstimatedMarginTooLow ? 'red' : 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                <div>
                    {/*<div style={{ fontSize: FontSizes.size14, marginBottom: 0, fontWeight: '600' }}>Факт</div>*/}
                    <Stack horizontal tokens={{ childrenGap: 30 }}>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Расходы факт" value={project.data.finance.summarySpending} onChange={(e) => changeFinance('summarySpending', e.target.value)} disabled={true} />
                            {spendsChanged && spendsBefore ? <div style={{fontSize: 12, marginTop: 3, marginLeft: 2, fontWeight: 600, color: '#a7a7a7'}}>Начальное значение: {new Intl.NumberFormat().format(spendsBefore)} руб.</div> : <></>}
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <MoneyField label="Маржа факт руб." value={project.data.finance.summaryMargin} onChange={(e) => changeFinance('summaryMargin', e.target.value)} disabled={true} />
                        </Stack>
                        <Stack tokens={{ childrenGap: 15 }} styles={{ root: { width: 300 } }}>
                            <TextField label="Маржа факт %" type="number" suffix="%" value={project.data.finance.marginPercent} onChange={(e) => changeFinance('marginPercent', e.target.value)} disabled={true} style={{color: 'black'}} />
                        </Stack>
                    </Stack>
                </div>
                {/*<Separator />*/}
                <div style={{marginTop: 40, marginBottom: 50}}>
                    <div style={{ fontSize: FontSizes.size14, marginBottom: 10, fontWeight: 'bold' }}>Комментарии</div>
                    <Comments project={project} reloadData={reloadData} type="FINANCE" setNotification={setNotification} />
                </div>
            </>
        }
    </Stack>;
}
