export default function calculate(project, setProject, actualSummaryPrice, setEstSpendsChanged, setSpendsChanged, key, value, value2, workers) {
    let isSteppedPayment = project.data.finance.isSteppedPayment;
    let prepaymentTitle = project.data.finance.prepayment.title;
    let postPaymentTitle = project.data.finance.postPayment.title;
    let summaryPrice = project.data.finance.summaryPrice;
    let estimatedSummarySpending = project.data.finance.estimatedSummarySpending;
    let summarySpending = project.data.finance.summarySpending;
    let summaryMargin = project.data.finance.summaryMargin;
    let marginPercent = project.data.finance.marginPercent;
    let estimatedSummaryMargin = project.data.finance.estimatedSummaryMargin;
    let estimatedMarginPercent = project.data.finance.estimatedMarginPercent;

    let prepaymentType = project.data.finance.prepayment.type;
    let prepaymentSum = project.data.finance.prepayment.sum;
    let prepaymentStatus = project.data.finance.prepayment.status;
    let prepaymentPaidAt = project.data.finance.prepayment.paidAt;

    let postPaymentType = project.data.finance.postPayment.type;
    let postPaymentSum = project.data.finance.postPayment.sum;
    let postPaymentStatus = project.data.finance.postPayment.status;
    let postPaymentPaidAt = project.data.finance.postPayment.paidAt;

    let prepaymentPercent, postPaymentPercent;

    switch (key) {
        case 'isSteppedPayment':
            isSteppedPayment = value;
            setProject({...project, data: {...project.data, finance: {...project.data.finance, isSteppedPayment: isSteppedPayment}}})
            break;
        case 'prepaymentTitle':
            setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'title': value}}}});
            break;
        case 'prepaymentType':
            prepaymentType = value;
            setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'type': value}}}});
            break;
        case 'prepaymentSum':
            prepaymentSum = value;
            prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
            if (!isSteppedPayment) {
                prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
            }
            setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'sum': value, title: prepaymentTitle}}}});
            break;
        case 'prepaymentStatus':
            prepaymentStatus = value;
            setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'status': value}}}});
            break;
        case 'prepaymentPaidAt':
            if (value === project.data.finance.prepayment.paidAt) {
                prepaymentPaidAt = null;
            } else {
                prepaymentPaidAt = value;
            }
            setProject({...project, data: {...project.data, finance: {...project.data.finance, prepayment: {...project.data.finance.prepayment, 'paidAt': prepaymentPaidAt}}}});
            break;
        case 'postPaymentType':
            postPaymentType = value;
            setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'type': value}}}});
            break;
        case 'postPaymentSum':
            postPaymentSum = value;
            postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
            if (!isSteppedPayment) {
                postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';
            }
            setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'sum': value, title: postPaymentTitle}}}});
            break;
        case 'postPaymentStatus':
            postPaymentStatus = value;
            setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'status': value}}}});
            break;
        case 'postPaymentPaidAt':
            if (value === project.data.finance.postPayment.paidAt) {
                postPaymentPaidAt = null;
            } else {
                postPaymentPaidAt = value;
            }
            setProject({...project, data: {...project.data, finance: {...project.data.finance, postPayment: {...project.data.finance.postPayment, 'paidAt': postPaymentPaidAt}}}});
            break;
        case 'summaryPrice':
            summaryPrice = value;

            if (estimatedSummarySpending) {
                estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
            } else {
                estimatedSummaryMargin = null;
                estimatedMarginPercent = null;
            }

            if (summarySpending) {
                summaryMargin = actualSummaryPrice - summarySpending;
                // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                // marginPercent = marginPercent < 0 ? null : marginPercent;
            } else {
                summaryMargin = null;
                marginPercent = null;
            }

            // prepaymentPercent = parseInt(prepaymentSum / summaryPrice * 100);
            // if (!isSteppedPayment) {
            //     prepaymentTitle = prepaymentPercent === 100 ? 'Оплата' : 'Предоплата ' + prepaymentPercent + '%';
            // }
            // postPaymentPercent = parseInt(postPaymentSum / summaryPrice * 100);
            // if (!isSteppedPayment) {
            //     postPaymentTitle = 'Постоплата ' + postPaymentPercent + '%';
            // }

            setProject({
                ...project,
                data: {
                    ...project.data,
                    finance: {
                        ...project.data.finance,
                        [key]: value,
                        'estimatedSummaryMargin': estimatedSummaryMargin,
                        'estimatedMarginPercent': estimatedMarginPercent,
                        'summaryMargin': summaryMargin,
                        'marginPercent': marginPercent,
                        prepayment: {...project.data.finance.prepayment, title: prepaymentTitle},
                        postPayment: {...project.data.finance.postPayment, title: postPaymentTitle},
                    }
                }
            });
            break;
        case 'estimatedSummarySpending':
            estimatedSummarySpending = value;

            if (estimatedSummarySpending) {
                estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
            } else {
                estimatedSummaryMargin = null;
                estimatedMarginPercent = null;
            }
            setEstSpendsChanged(true);
            setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent}}});
            break;
        case 'summarySpending':
            summarySpending = value;

            if (summarySpending) {
                summaryMargin = actualSummaryPrice - summarySpending;
                // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                // marginPercent = marginPercent < 0 ? null : marginPercent;
            } else {
                summaryMargin = null;
                marginPercent = null;
            }
            setSpendsChanged(true);
            setProject({...project, data: {...project.data, finance: {...project.data.finance, [key]: value, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent}}});
            break;
        // case 'summaryMargin':
        //     summaryMargin = value;
        //     break;
        // case 'marginPercent':
        //     marginPercent = value;
        //     break;
        // case 'estimatedSummaryMargin':
        //     estimatedSummaryMargin = value;
        //     break;
        // case 'estimatedMarginPercent':
        //     estimatedMarginPercent = value;
        //     break;
        case 'estimatedSummarySpending|summarySpending':
            estimatedSummarySpending = value;
            if (estimatedSummarySpending) {
                estimatedSummaryMargin = summaryPrice - estimatedSummarySpending;
                // estimatedSummaryMargin = estimatedSummaryMargin < 0 ? null : estimatedSummaryMargin;
                estimatedMarginPercent = parseInt((100 / summaryPrice) * estimatedSummaryMargin);
                // estimatedMarginPercent = estimatedMarginPercent < 0 ? null : estimatedMarginPercent;
            } else {
                estimatedSummaryMargin = null;
                estimatedMarginPercent = null;
            }

            summarySpending = value2;
            if (summarySpending) {
                summaryMargin = actualSummaryPrice - summarySpending;
                // summaryMargin = summaryMargin < 0 ? null : summaryMargin;
                marginPercent = parseInt((100 / actualSummaryPrice) * summaryMargin);
                // marginPercent = marginPercent < 0 ? null : marginPercent;
            } else {
                summaryMargin = null;
                marginPercent = null;
            }

            setEstSpendsChanged(true);
            setSpendsChanged(true);
            setProject({...project, data: {...project.data, finance: {...project.data.finance, estimatedSummarySpending: value, 'estimatedSummaryMargin': estimatedSummaryMargin, 'estimatedMarginPercent': estimatedMarginPercent, summarySpending: value2, 'summaryMargin': summaryMargin, 'marginPercent': marginPercent, workers: workers}}});
            break;
        default:
            break;
    }

    return {
        id: project.data.id,
        summaryPrice: summaryPrice,
        estimatedSummarySpending: estimatedSummarySpending,
        summarySpending: summarySpending,
        summaryMargin: summaryMargin,
        marginPercent: marginPercent,
        estimatedSummaryMargin: estimatedSummaryMargin,
        estimatedMarginPercent: estimatedMarginPercent,
        prepaymentType: prepaymentType,
        prepaymentSum: prepaymentSum,
        prepaymentTitle: prepaymentTitle,
        prepaymentStatus: prepaymentStatus,
        prepaymentPaidAt: prepaymentPaidAt,
        postPaymentType: postPaymentType,
        postPaymentSum: postPaymentSum,
        postPaymentTitle: postPaymentTitle,
        postPaymentStatus: postPaymentStatus,
        postPaymentPaidAt: postPaymentPaidAt,
        isSteppedPayment: isSteppedPayment,
    };
}
